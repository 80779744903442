import { useState, createContext, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthToken } from 'models/AuthToken';
import { AuthUser } from 'models/AuthUser';
import {
  FUSION_AUTH_API_URL_DEV,
  FUSION_AUTH_API_URL_STAGE,
  FUSION_AUTH_CLIENT_ID_DEV,
  FUSION_AUTH_CLIENT_ID_STAGE,
  NODE_ENV_DEV
} from '../constants/global';
import { useHistory } from 'react-router-dom';

export interface Auth {
  user?: AuthUser;
  token?: AuthToken;
}

export const AuthContext = createContext<Auth>({
  user: undefined,
  token: undefined
})

type AuthProviderInterface = {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<AuthProviderInterface> = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState<any>();
  const [token, setToken] = useState<any>();
 
  if (document.URL.includes('?') &&
      document.URL.includes('uid=') &&
      document.URL.includes('&at=') &&
      document.URL.includes('admin/default') && 
      document.URL.includes('tid=') && 
      document.URL.includes('rt='))  {
    
    const data = document.URL.split('?');
    const params = data[1].split('&');
    const userId = (params[0].split('uid='))[1];
    const tenantId = (params[1].split('tid='))[1];
    const accessToken = (params[2].split('at='))[1];
    const refreshToken = (params[3].split('rt='))[1];

    if (userId && accessToken && refreshToken && tenantId) {

      useEffect(() => {
    
      let authUrl, clientId;

      if (process.env.NODE_ENV === NODE_ENV_DEV) {
        authUrl = `${FUSION_AUTH_API_URL_DEV}/api/auth`;
        clientId = FUSION_AUTH_CLIENT_ID_DEV;
      } else {
        authUrl = `${FUSION_AUTH_API_URL_STAGE}/api/auth`;
        clientId = FUSION_AUTH_CLIENT_ID_STAGE;
      }

      axios({
        url: authUrl,
        method: 'post',
        headers: {
          'Authorization': `Basic ${accessToken}`
        },
        data: {
          userId,
          tenantId,
          refreshToken,
          clientId
        }
      })
        .then(response => response.data)
        .then(response => {
          if (!response.userId && !response.token) {
            if (window.location.href.includes('admin/default')) {
              let logoutURL;

              if (process.env.NODE_ENV === NODE_ENV_DEV) {
                logoutURL = `${FUSION_AUTH_API_URL_DEV}/api/logout?clientId=${FUSION_AUTH_CLIENT_ID_DEV}&tenantId=${tenantId}`
              } else {
                logoutURL = `${FUSION_AUTH_API_URL_STAGE}/api/logout?clientId=${FUSION_AUTH_CLIENT_ID_STAGE}&tenantId=${tenantId}`
              }

              window.location.href = logoutURL;
            }
          } else {
            setUser(response.userId);
            setToken(response.token);
          }
        }).catch(err => {
          console.error('error: ', err);
        })});
    }
  } else {
    history.push('/auth');
  }

  return <AuthContext.Provider value={{
    user,
    token,
  }}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext);

import {
	Avatar,
	Divider,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { ItemContent } from 'components/menu/ItemContent';
import PropTypes from 'prop-types';
import { MdNotificationsNone } from 'react-icons/md';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconMyAccount } from 'assets/img/icons/my-account.svg'
import { ReactComponent as IconSupport } from 'assets/img/icons/support.svg'
import { ReactComponent as IconLogout } from 'assets/img/icons/logout.svg'
import { useAuth } from 'contexts/AuthContext';
import {
	FUSION_AUTH_API_URL_DEV,
	FUSION_AUTH_API_URL_STAGE,
	FUSION_AUTH_CLIENT_ID_DEV,
	FUSION_AUTH_CLIENT_ID_STAGE,
	FUSION_AUTH_TENANT_ID_DEV,
	FUSION_AUTH_TENANT_ID_STAGE,
	NODE_ENV_DEV 
} from 'constants/global';

export default function HeaderLinks(props: { secondary: boolean }) {

	const { user } = useAuth();

	const { secondary } = props;

	const menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const menuItemTextColor = useColorModeValue('gray.100', 'darkGray.100');
	const menuItemSecondaryTextColor = useColorModeValue('gray.200', 'darkGray.200');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const handleLogout = () => {
		let logoutURL;

		if (process.env.NODE_ENV === NODE_ENV_DEV) {
			logoutURL = `${FUSION_AUTH_API_URL_DEV}/api/logout?clientId=${FUSION_AUTH_CLIENT_ID_DEV}&tenantId=${FUSION_AUTH_TENANT_ID_DEV}`
		} else {
			logoutURL = `${FUSION_AUTH_API_URL_STAGE}/api/logout?clientId=${FUSION_AUTH_CLIENT_ID_STAGE}&tenantId=${FUSION_AUTH_TENANT_ID_STAGE}`
		}

		window.location.href = logoutURL;
	}

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			bg={'transparent'}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='30px'
			boxShadow={'none'}>
			<Menu>
				<MenuButton p='0px'>
					<Flex
						direction={'row'}
						alignItems='center'
					>
						<Avatar
							_hover={{ cursor: 'pointer' }}
							color='white'
							name={`${user?.given_name} ${user?.family_name}`}
							bg='#11047A'
							size='sm'
							w='50px'
							h='50px'
						/>
						<Flex
							ml='10px'
							direction='column'
							alignItems={'flex-start'}
						>
							<Text
								fontWeight='700'
								color={textColor}>
								{user?.given_name} {user?.family_name}
							</Text>
							<Text
								fontWeight='500'
								color={textColor}>
								ACME Corp.
							</Text>
						</Flex>
					</Flex>
				</MenuButton>
				<MenuList boxShadow={shadow} borderColor={borderColor} borderWidth={1} p='0px' mt='10px' borderRadius='20px' bg={menuBg}>
					<Flex flexDirection='column' p='10px'>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
							<IconMyAccount />
							<Text fontWeight={'bold'} fontSize={'16px'} color={menuItemTextColor} ml={'10px'}>My Account</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px' mb={'5px'}>
							<IconSupport />
							<Text fontWeight={'bold'} fontSize={'16px'} color={menuItemTextColor} ml={'10px'}>Support</Text>
						</MenuItem>
						<Divider />
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='red.400'
							borderRadius='8px'
							px='14px'
							onClick={handleLogout}>
							<IconLogout />
							<Text fontWeight={'medium'} fontSize={'16px'} color={menuItemSecondaryTextColor} ml={'10px'}>Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>

			<Menu>
				<MenuButton ml='20px'>
					<Flex
						width={'50px'}
						height={'50px'}
						borderRadius={'25px'}
						alignItems={'center'}
						justifyContent={'center'}
						bgColor={globalStyles.colors.green[100]}
					>
						<Icon as={MdNotificationsNone} color={'white'} w='32px' h='32px' />
					</Flex>
				</MenuButton>

				<MenuList
					boxShadow={shadow}
					p='20px'
					borderRadius='20px'
					bg={menuBg}
					borderColor={borderColor}
					borderWidth={1}
					mt='22px'
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex w='100%' mb='20px'>
						<Text fontSize='md' fontWeight='600' color={textColor}>
							Notification Center
						</Text>
						<Text fontSize='sm' fontWeight='500' color={textColorBrand} ms='auto' cursor='pointer'>
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection='column'>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px='0' borderRadius='8px' mb='10px'>
							<ItemContent info='Test notification 1' />
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px='0' borderRadius='8px' mb='10px'>
							<ItemContent info='Test notification 2' />
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};

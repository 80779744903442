import { Box, Button, Checkbox, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconInstantFund } from 'assets/img/icons/icon-instant-fund.svg';
import { ReactComponent as IconReplaceCard } from 'assets/img/icons/replace-card.svg';
import CardStatusView from 'components/dataDisplay/CardStatusView';

export default function CardholderRow(props: { card: any, onSelect: (card: any, selected: boolean) => void, isLastCell?: boolean, isSelected?: boolean }) {

	const { card, onSelect, isSelected, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const fontSize = { base: '14px', '2xl': '16px' }

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'}>
					<Checkbox isChecked={isSelected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(card, e.target.checked)}/>
					<Box w='20px' />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='80px'>
					<Text color={textColor} fontSize={fontSize}>
						{card.clientId}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='120px'>
					<Text color={globalStyles.colors.primary[100]} fontSize={fontSize} textDecoration='underline'>
						{card.cardHolder}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='100px' display={{ base: 'none', '2xl': 'flex' }}>
					<Text color={textColor} fontSize={fontSize}>
						{card.employeeID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'110px'} display={{ base: 'none', '2xl': 'flex' }}>
					<Text color={textColor} fontSize={fontSize}>
						•••-•••-{card.ssnLast4Digits}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'center'} alignItems={'center'} width='100px'>
					<Text color={textColor} fontSize={fontSize}>
						• {card.cardNumber}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'center'} alignItems={'center'} width={'80px'}>
					<CardStatusView status={card.cardStatus} />
				</Flex>
				<Flex h='100%' justifyContent={'center'} alignItems={'center'} width={'160px'}>
					<Text color={textColor} fontSize={fontSize}>
						{card.assignedDate}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'center'} alignItems={'center'} flex={1} direction='row'>
					<Button disabled={isSelected} leftIcon={<IconInstantFund />} fontSize={'14px'} fontWeight='bold' variant="ghost" borderRadius={'30px'} colorScheme='blue'>
						Instant Fund
					</Button>
					<Button disabled={isSelected} leftIcon={<IconReplaceCard />} fontSize={'14px'} fontWeight='bold' variant="ghost" borderRadius={'30px'} colorScheme='blue'>
						Replace Card
					</Button>
				</Flex>
			</Flex>
			{!props.isLastCell && <Divider variant="dashed" />}
		</>
	);
}

export const PermissionGroupsListMock1 = [
  {
    clientID: '0942653',
    name: 'Default Group',
    assignedUser: 12,
    permissionCount: 5,
    permissions: 'User Management: Create User and 3 more',
  },
  {
    clientID: '1235648',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '6489213',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '3125463',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '1956874',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '1546999',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '4625123',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '8525636',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '7985465',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '6589000',
    name: 'Default Group',
    assignedUser: 11,
    permissionCount: 4,
    permissions: 'User Management: View User and 2 more',
  },
]

export const PermissionGroupsListMock2 = [
  {
    clientID: '2132515',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '6396545',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '1285496',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '3065444',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '1000985',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '9784563',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '1115222',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '6665332',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '6489213',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
  {
    clientID: '00032578',
    name: 'Default Group',
    assignedUser: 10,
    permissionCount: 3,
    permissions: 'User Management: View User and 2 more',
  },
]
import { Box, Button, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as IconArrowDown } from 'assets/img/icons/arrow-dropdown.svg';
import { ItemContent } from 'components/menu/ItemContent';
import { globalStyles } from 'theme/styles';

export default function TableSortOptionsBar(props: { fields: any[] }) {


  const { fields } = props

	const textColor = useColorModeValue('gray.100', 'darkGray.100');
  const menuBg = useColorModeValue('gray.600', 'darkGray.600')
  const borderColor = useColorModeValue('gray.300', 'darkGray.300')

	return (
		<Flex direction={'row'} alignItems='center' minH='54px' padding={'0 0 0 30px'}>
      {fields.map(item => (
        <Flex direction='row' key={item.key} alignItems='center' mr={'30px'}>
          <Text color={textColor} fontSize={'14px'} fontWeight='700'>
            {item.name}
          </Text>
          <Menu>
            <MenuButton ml='5px'>
              <Flex direction='row' alignItems='center'>
                <Text color={globalStyles.colors.primary[100]} fontSize={'14px'} fontWeight='700' mr={'5px'}>
                  {item.options[0].name}
                </Text>
                <IconArrowDown />
              </Flex>
              
            </MenuButton>

            <MenuList
              padding='10px 16px'
              minW='unset'
              borderRadius='5px'
              bg={menuBg}
              borderColor={borderColor}
              borderWidth={1}
              w={'200px'}
              h={'64px'}
              >
              {/* <Flex flexDirection='column'>
                <Text color={globalStyles.colors.primary[100]} fontSize={'14px'}>
                  {item.options[0].name}
                </Text>
                <Text color={globalStyles.colors.primary[100]} fontSize={'14px'}>
                  {item.options[0].name}
                </Text>
              </Flex> */}
            </MenuList>
          </Menu>
        </Flex>
      ))}
    </Flex>
	);
}

import { Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconFailed } from 'assets/img/icons/failed.svg';

export default function SyndDataRow(props: { data: any, isLastCell?: boolean }) {

	const { data } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const fontSize = { base: '14px', '2xl': '16px' }

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'60px'}>
					<Text color={textColor} fontSize={fontSize}>
						{data.jobID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'100px'}>
					<Text color={textColor} fontSize={fontSize}>
						{data.clientID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={fontSize} textDecoration='underline'>
						{data.method}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{data.created}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{data.completed}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Flex direction='row' alignItems="center">
						<Text color={data.jobStatus === 'Fail' ? globalStyles.colors.red[100] : textColor} fontSize={fontSize} mr={'5px'}>
							{data.jobStatus}
						</Text>
						{data.jobStatus === 'Fail' && <IconFailed />}
					</Flex>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						Processed row
					</Text>
				</Flex>
			</Flex>
			{!props.isLastCell && <Divider variant="dashed" />}
		</>
	);
}

import MainDashboard from 'views/admin/default';
import { ReactComponent as IconMenuDashboard } from 'assets/img/layout/icon-menu-dashboard.svg';
import { ReactComponent as IconMenuUserManagement } from 'assets/img/layout/icon-menu-user-management.svg';
import { ReactComponent as IconMenuCardholderManagement } from 'assets/img/layout/icon-menu-cardholder-management.svg';
import { ReactComponent as IconMenuDataSynchronization} from 'assets/img/layout/icon-menu-data-synchronization.svg';
import { ReactComponent as IconMenuCardInventory} from 'assets/img/layout/icon-menu-card-inventory.svg';
import UsersManagement from 'views/admin/users';
import CardholderManagement from 'views/admin/cardholderManagement';
import DataSynchronization from 'views/admin/dataSynchronization';
import CardInventory from 'views/admin/cardInventory';

const routes = [
	{
		name: 'Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <IconMenuDashboard fill='currentColor' />,
		component: MainDashboard
	},
	{
		name: 'User Management',
		layout: '/admin',
		path: '/users',
		icon: <IconMenuUserManagement fill='currentColor' />,
		component: UsersManagement,
	},
	{
		name: 'Cardholder Management',
		layout: '/admin',
		icon: <IconMenuCardholderManagement fill='currentColor' />,
		path: '/cardholders',
		component: CardholderManagement
	},
	{
		name: 'Data Synchronization',
		layout: '/admin',
		icon: <IconMenuDataSynchronization fill='currentColor' />,
		path: '/data-synchronization',
		component: DataSynchronization
	},
	{
		name: 'Card Inventory',
		layout: '/admin',
		path: '/profile',
		icon: <IconMenuCardInventory fill='currentColor' />,
		component: CardInventory
	},
];

export default routes;

import { Box, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as IconArrowDown } from 'assets/img/icons/arrow-down.svg';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconInstantFund } from 'assets/img/icons/icon-instant-fund.svg';
import CardStatusView, { CardStatus } from 'components/dataDisplay/CardStatusView';

export default function CardRow(props: { card: any, isLastCell?: boolean }) {

	const { card, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const fontSize = { base: '14px', '2xl': '16px' }

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={{base: '140px', '2xl': '200px'}}>
					<Text color={textColor} fontSize={fontSize}>
						{card.assignedDate}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={{base: '80px', '2xl': '120px'}}>
					<Text color={textColor} fontSize={fontSize}>
						{card.clientId}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={fontSize} textDecoration='underline'>
						{card.cardHolder}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{card.employeeID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1} display={{ base: 'none', '2xl': 'flex' }}>
					<Text color={textColor} fontSize={fontSize}>
						•••-•••-{card.ssnLast4Digits}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						• {card.cardNumber}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<CardStatusView status={card.cardStatus} />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{card.cardType}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{card.assignedBy}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'140px'} direction='row'>
					<IconInstantFund />
					<Text color={textColor} fontSize={fontSize} fontWeight='bold' ml={'5px'}>
						Instant Fund
					</Text>
				</Flex>
			</Flex>
			{!props.isLastCell && <Divider variant="dashed" />}
		</>
	);
}

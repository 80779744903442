// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { Redirect, Route, Switch } from 'react-router-dom';
import SectionBar from 'components/sectionbar/SectionBar';
import AllUsers from './components/SyncHistory';
import SFTPInformation from './components/SFTPInformation';
import DataUpload from './components/DataUpload';
import Settings from './components/Settings';

export default function DataSynchronization() {

	const borderColor = useColorModeValue('gray.400', 'darkGray.400')

	const routes = [
		{
			name: 'Sync History',
			layout: '/admin/data-synchronization',
			path: '/sync-history',
			component: AllUsers
		},
		{
			name: 'SFTP Information',
			layout: '/admin/data-synchronization',
			path: '/sftp-information',
			component: SFTPInformation,
		},
		{
			name: 'Data Upload',
			layout: '/admin/data-synchronization',
			path: '/data-upload',
			component: DataUpload,
		},
		{
			name: 'Settings',
			layout: '/admin/data-synchronization',
			path: '/settings',
			component: Settings,
		},
	]

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Card flexDirection='column' w='100%' mb='0px' minH='calc(100vh - 160px)' padding='24px 24px' borderWidth={'1px'} borderColor={borderColor} borderRadius='6px'>
				<SectionBar routes={routes}/>
				<Box>
					<Switch>
						{routes.map(route=>(
							<Route path={route.layout + route.path} component={route.component} key={route.path} />	
						))}
						<Redirect from='/' to='/admin/data-synchronization/sync-history' />
					</Switch>
				</Box>
			</Card>
		</Box>
	);
}

// chakra imports
import { Box, Button, Flex, Icon, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[], onClose?: () => void }) {
	const { routes } = props;
	const { colorMode, toggleColorMode } = useColorMode();
	const navbarIcon = useColorModeValue('gray.400', 'white');
	// SIDEBAR
	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='24px'
				w='max-content'
				position={'absolute'}
				left={'20px'}
				onClick={toggleColorMode}>
				<Icon
					me='10px'
					h='18px'
					w='18px'
					color={navbarIcon}
					as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
				/>
			</Button>
			<Brand />
			<Stack direction='column' mt='8px' mb='auto'>
				<Box ps='0' pe={{ lg: '16px', '2xl': '16px' }}>
					<Links routes={routes} onClose={props.onClose}/>
				</Box>
			</Stack>

			<Box ps='20px' pe={{ lg: '16px', '2xl': '20px' }} mt='60px' mb='40px' borderRadius='30px'>
				<SidebarCard />
			</Box>
		</Flex>
	);
}

export default SidebarContent;

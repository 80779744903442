import { useEffect, useState } from 'react'
import { Button, Flex } from "@chakra-ui/react"
import { ReactComponent as IconLeft } from 'assets/img/icons/arrow-left.svg'
import { ReactComponent as IconRight } from 'assets/img/icons/arrow-right.svg'

type Props = {
  total: number,
  current?: number,
  pageStart?: number,
  onNext?: () => void,
  onPrevious?: () => void,
  onPageChanged?: (pageNumber: number) => void,
}

export default function Paging({ total, pageStart = 0, onNext, onPrevious, onPageChanged, current = 0 }: Props) {

  const [currentPage, setCurrentPage] = useState(current)

  const onPage = (idx: number) => {
    setCurrentPage(idx)
    onPageChanged?.(idx)
  }

  useEffect(() => {
    setCurrentPage(current)
  }, [current])

  if (total === 0) {
    return null
  }

  return (
    <Flex direction={'row'} alignSelf='center' mt={'20px'}>
      <Button onClick={onPrevious} variant={'ghost'} leftIcon={<IconLeft />} colorScheme="blue" mr={'10px'}>
        Previous
      </Button>
      {[...Array(total).keys()].map( idx => (
        <Button key={idx} onClick={() => onPage(pageStart + idx)} variant={'outline'} colorScheme={pageStart + idx === currentPage ? 'blue' : 'gray'} borderRadius={4} mr={'10px'} fontSize={'14px'}>
          {pageStart + idx + 1}
        </Button>
      ))}
      <Button onClick={onNext} variant={'ghost'} rightIcon={<IconRight />} colorScheme="blue">
        Next
      </Button>
    </Flex>
  )
}
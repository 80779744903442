import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './assets/css/App.css';
import theme from './theme/theme';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { AuthContextProvider } from 'contexts/AuthContext';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<BrowserRouter>
				<AuthContextProvider>
					<Switch>
						<Route exact path="/" render={() => { return ( <Redirect to="/auth" />  ) }} />
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} component={AdminLayout} />
					</Switch>
				</AuthContextProvider>
			</BrowserRouter>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);

export const CardholdersMock1 = [
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5432675',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
  },
  {
    assignedDate: '6/8/2022 9:30 am ET',
    clientId: '0942562',
    cardHolder: 'Aron Lidia',
    employeeID: '54326',
    ssnLast4Digits: '8653',
    cardNumber: '6323',
    cardStatus: 'frozen',
  },
  {
    assignedDate: '6/8/2022 9:12 am ET',
    clientId: '1096352',
    cardHolder: 'Berta Dinesh',
    employeeID: '49261',
    ssnLast4Digits: '1378',
    cardNumber: '1378',
    cardStatus: 'activation',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '9784556',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5000124',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'restricted',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '9000232',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
  },
  {
    assignedDate: '6/8/2022 9:12 am ET',
    clientId: '0032515',
    cardHolder: 'Berta Dinesh',
    employeeID: '49261',
    ssnLast4Digits: '1378',
    cardNumber: '1378',
    cardStatus: 'activation',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '1845777',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '9152366',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'restricted',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '1254215',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
  },
]
export const FUSION_AUTH_URL_STAGE = 'https://kurense-staging.fusionauth.io';
export const FUSION_AUTH_URL_DEV = 'https://kurense-dev.fusionauth.io';
export const FUSION_AUTH_API_URL_STAGE = 'https://account.kurense.io';
export const FUSION_AUTH_API_URL_DEV = 'https://account-dev.kurense.io';
export const FUSION_AUTH_CLIENT_ID_STAGE = 'f39a092b-8459-4de4-aa3b-8366dd27ce99';
export const FUSION_AUTH_CLIENT_ID_DEV = '08792f93-871a-472e-a10e-370c88e1f3bf';
export const FUSION_AUTH_TENANT_ID_STAGE = 'defd804d-def2-0047-5951-83727755271d';
export const FUSION_AUTH_TENANT_ID_DEV = '57af4b86-041d-dd5c-9f92-b99b2359ab85';
export const FUSION_AUTH_REDIRECT_URL_STAGE = `${FUSION_AUTH_API_URL_STAGE}/api/cb`;
export const FUSION_AUTH_REDIRECT_URL_DEV = `${FUSION_AUTH_API_URL_DEV}/api/cb`;

export const NODE_ENV_DEV = 'development';

// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

import iPayLogo from 'assets/img/layout/iPayLogo.png';

export function SidebarBrand() {
	return (
		<Flex alignItems='center' flexDirection='column' mt={'60px'}>
			<img src={iPayLogo} style={{ width: '104px', height: '54px' }} />
		</Flex>
	);
}

export default SidebarBrand;

export const SyncErrorsMock = [
  {
    jobID: '35425',
    clientID: '0942653',
    cardHolder: 'Siobhan Preece',
    employeeID: '15624',
    account: '749375923',
    ssnLast4Digits: '4525',
    errorReason: 'Unable to retrieve data due to masking',
    created: '6/9/2022 12:30pm ET',
    completed: '6/9/2022 3:30pm ET',
    jobStatus: 'Processing',
  },
  {
    jobID: '76545',
    clientID: '4956325',
    cardHolder: 'Laurie Glover',
    employeeID: '57546',
    account: '101975923',
    ssnLast4Digits: '4525',
    errorReason: 'Fields impacted: SSN, account number, routing number',
    created: '6/9/2022 1:30pm ET',
  },
  {
    jobID: '35425',
    clientID: '0942653',
    cardHolder: 'Siobhan Preece',
    employeeID: '15624',
    account: '749375923',
    ssnLast4Digits: '4525',
    errorReason: 'BIN nuber indicates card is not an iPay card',
    created: '6/9/2022 2:00pm ET',
  },
  {
    jobID: '35425',
    clientID: '0942653',
    cardHolder: 'Siobhan Preece',
    employeeID: '15624',
    account: '749375923',
    ssnLast4Digits: '4525',
    errorReason: 'Missing address',
  },
  {
    jobID: '35425',
    clientID: '0942653',
    cardHolder: 'Siobhan Preece',
    employeeID: '15624',
    account: '749375923',
    ssnLast4Digits: '4525',
    errorReason: 'Unable to provision card account',
  },
  {
    jobID: '35425',
    clientID: '0942653',
    cardHolder: 'Siobhan Preece',
    employeeID: '15624',
    account: '749375923',
    ssnLast4Digits: '4525',
    errorReason: 'No active card found for account',
  },
]
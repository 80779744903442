import { useEffect, useState } from 'react';
import { Button, Flex } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { ReactComponent as IconExport } from 'assets/img/icons/export.svg';
import { CardholdersMock1 } from 'models/__mocks__/CardholdersMocks';
import { globalStyles } from 'theme/styles';
import { DataSyncMocks } from 'models/__mocks__/DataSyncMocks';
import SyndDataRow from 'components/dataRows/SyndDataRow';

const fields = [
	{
		text: 'Job ID',
		keyword: 'job-id',
		width: '60px',
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		width: '100px',
	},
	{
		text: 'Method',
		flex: 1,
		keyword: 'method',
	},
	{
		text: 'Created',
		keyword: 'created',
		withArrow: true,
		flex: 1,
	},
	{
		text: 'Completed',
		flex: 1,
		keyword: 'completed',
	},
	{
		text: 'Job Status',
		keyword: 'job-status',
		flex: 1,
	},
	{
		text: 'Processed',
		keyword: 'processed',
		flex: 1,
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{ key: 'assignment_date', name: 'Assignment Date' }, { key: 'option2', name: 'Option 2' }],
		default: 'assignment_date'
	},
	{
		key: 'client_id',
		name: 'Client ID',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'created_date',
		name: 'Crated Date',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'method',
		name: 'Method',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'job-status',
		name: 'Job Status',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
]

export default function SyncHistory() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [currentPageItems, setCurrentPageItems] = useState<any[]>(CardholdersMock1)

	useEffect(() => {
		setCurrentPageItems(currentPage % 2 === 0 ? DataSyncMocks : DataSyncMocks)
	}, [])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by keywords' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconExport />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} colorScheme='blue'>
					Export
				</Button>
				<Button variant="solid" borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} ml={'16px'} colorScheme='blue' bgColor={globalStyles.colors.primary[100]}>
					Run Sync
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader fields={fields} />
			{DataSyncMocks.map((item, index) => (
				<SyndDataRow
					data={item}
					isLastCell={index === currentPageItems.length - 1}
					key={index}
				/>
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart} 
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
		</Flex>
	)

}
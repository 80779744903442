import { Button, Flex, Menu, MenuButton, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import contactUs from 'assets/img/contact/img-contact-us.png';
import { ReactComponent as IconVideoCall } from 'assets/img/icons/video-call.svg';
import { ReactComponent as IconPhoneCall } from 'assets/img/icons/phone-call.svg';
import { ReactComponent as IconChat } from 'assets/img/icons/chat.svg';

export default function SidebarDocs() {
	const bgColor = globalStyles.colors.brand[500];
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	const ActionItem = ({ icon, title }: {icon: React.ReactElement, title: string}) => (
		<Button leftIcon={icon} w={'100%'} justifyContent='flex-start' bgColor={globalStyles.colors.primary[100]} textColor='white' borderRadius={50} fontWeight='normal' mt={'10px'}>
			{title}
		</Button>
	)

	return (
		<Menu>
			<MenuButton>
				<Flex
					direction='row'
					alignItems={'center'}
					bg={bgColor}
					borderRadius='30px'
					me={{}}
					position='relative'
					height={'60px'}
					width={'264px'}
				>
					<img src={contactUs} style={{ width: '100px', height: '100px', marginLeft: '-20px' }} />
					<Text
						color='white'
						fontWeight="semibold"
						fontSize={18}
						lineHeight='120%'
						textAlign='center'
						letterSpacing={0.5}
						textShadow='none'
						>
						Contact us now
					</Text>
				</Flex>
			</MenuButton>
			<MenuList
				p='20px'
				borderRadius='20px'
				border='1px'
				w={'262px'}
				borderColor={borderColor}
				alignItems='center'
				>
				<Text fontSize='16px' fontWeight='bold' color={textColor} textAlign='center'>
					We are here to help
				</Text>
				<ActionItem icon={<IconVideoCall />} title='Video Call' />
				<ActionItem icon={<IconPhoneCall />} title='Phone' />
				<ActionItem icon={<IconChat />} title='Online Chat' />
			</MenuList>
		</Menu>
	);
}

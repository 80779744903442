import { Box, Checkbox, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as IconArrowDown } from 'assets/img/icons/arrow-down.svg';

export default function TableFixedHeader(props: { fields: any[], isIndeterminate?: boolean, isChecked?: boolean, onCheck?: (checked: boolean) => void }) {

	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	return (
		<>
			<Divider />
			<Flex direction={'row'} alignItems='center' minH='44px' padding={'0 0 0 30px'}>
				{props.fields.map(field => (
					<Flex key={field.keyword} w={field.width} h='100%' justifyContent={field.align === 'center' ? 'center' : 'flex-start'} alignItems={'center'} flex={field.flex} display={field.display}>
						{field.checking ? (
							<>
								<Checkbox
									isChecked={props.isChecked}
									isIndeterminate={props.isIndeterminate}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onCheck?.(e.target.checked)}
								/>
								<Box w='20px' />
							</>
						) : (
							<>
								<Text color={textColor} fontSize={'14px'} fontWeight='bold'>
									{field.text}
								</Text>
								{field.withArrow && (
									<>
										<Box w={'5px'} />
										<IconArrowDown />
									</>
								)}
							</>
						)}
					</Flex>
				))}
			</Flex>
			<Divider />
		</>
	);
}

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { Redirect, Route, Switch } from 'react-router-dom';
import SectionBar from 'components/sectionbar/SectionBar';
import AllUsers from './components/AllUsers';
import PermissionGroupList from './components/PermissionGroupList';
import UserActivities from './components/UserActivities';

export default function UsersManagement() {

	const borderColor = useColorModeValue('gray.400', 'darkGray.400')

	const routes = [
		{
			name: 'All users',
			layout: '/admin/users',
			path: '/all',
			component: AllUsers
		},
		{
			name: 'Permission Group',
			layout: '/admin/users',
			path: '/permissions',
			component: PermissionGroupList,
		},
		{
			name: 'User Activity',
			layout: '/admin/users',
			path: '/activity',
			component: UserActivities,
		},
	]

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Card flexDirection='column' w='100%' mb='0px' minH='calc(100vh - 160px)' padding='24px 24px' borderWidth={'1px'} borderColor={borderColor} borderRadius='6px'>
				<SectionBar routes={routes}/>
				<Box>
					<Switch>
						{routes.map(route=>(
							<Route path={route.layout + route.path} component={route.component} key={route.path} />	
						))}
						<Redirect from='/' to='/admin/users/all' />
					</Switch>
				</Box>
			</Card>
		</Box>
	);
}

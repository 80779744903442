import { Box, Checkbox, Divider, Flex, IconButton, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconMoreHoriz } from 'assets/img/icons/more-horiz.svg';

export default function PermissionGroupsRow(props: { permission: any, onSelect: (user: any, selected: boolean) => void, isLastCell?: boolean, isSelected?: boolean }) {

	const { permission, onSelect, isSelected, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	const textFontSize = '14px'

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'}>
					<Checkbox isChecked={isSelected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(permission, e.target.checked)}/>
					<Box w='20px' />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={0.7}>
				<Text color={globalStyles.colors.primary[100]} fontSize={textFontSize} textDecoration='underline'>
						{permission.name}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'100px'}>
					<Text color={textColor} fontSize={textFontSize}>
						{permission.clientID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={textFontSize} textDecoration='underline'>
						{permission.assignedUser}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'100px'}>
					<Text color={textColor} fontSize={textFontSize}>
						{permission.permissionCount}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={2}>
					<Text color={textColor} fontSize={textFontSize}>
						{permission.permissions}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='120px' direction='row'>
					<IconButton aria-label='more' icon={<IconMoreHoriz />} variant='ghost' colorScheme={'blue'} w={'40px'} h={'40px'} borderRadius='20px'/>
				</Flex>
			</Flex>
			{!isLastCell && <Divider variant="dashed" />}
		</>
	);
}

export const assignedCards = [
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5432675',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
    cardType: 'New',
    assignedBy: 'Sync',
  },
  {
    assignedDate: '6/8/2022 9:30 am ET',
    clientId: '0942562',
    cardHolder: 'Aron Lidia',
    employeeID: '54326',
    ssnLast4Digits: '8653',
    cardNumber: '6323',
    cardStatus: 'activation',
    cardType: 'New',
    assignedBy: 'Sync',
  },
  {
    assignedDate: '6/8/2022 9:12 am ET',
    clientId: '4926321',
    cardHolder: 'Berta Dinesh',
    employeeID: '49261',
    ssnLast4Digits: '1378',
    cardNumber: '1378',
    cardStatus: 'active',
    cardType: 'Replacement',
    assignedBy: 'Sync',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5432675',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
    cardType: 'New',
    assignedBy: 'Sync',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5432675',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
    cardType: 'New',
    assignedBy: 'Sync',
  },
  {
    assignedDate: '6/8/2022 9:45 am ET',
    clientId: '5432675',
    cardHolder: 'Kevin Leonard',
    employeeID: '54326',
    ssnLast4Digits: '1674',
    cardNumber: '8263',
    cardStatus: 'active',
    cardType: 'New',
    assignedBy: 'Sync',
  },
]

export const ACHRejectsMock = [
  {
    card: {
      assignedDate: '6/8/2022 9:45 am ET',
      clientId: '5432675',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '6/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '6/8/2022 9:45 am ET',
      clientId: '1919199',
      cardHolder: 'Stevie Lloyd',
      employeeID: '54326',
      ssnLast4Digits: '3465',
      cardNumber: '9102',
      cardStatus: 'frozen',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '6/9/2022',
    amount: 50000,
    rejectReason: 'Unable to Locate Account',
    transactionType: 'Instant Fund',
  },
  {
    card: {
      assignedDate: '6/8/2022 9:45 am ET',
      clientId: '4658463',
      cardHolder: 'Yazmin Brown',
      employeeID: '54326',
      ssnLast4Digits: '3465',
      cardNumber: '9102',
      cardStatus: 'active',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '6/9/2022',
    amount: 32000,
    rejectReason: 'Unable to Locate Account',
    transactionType: 'Instant Fund',
  },
  {
    card: {
      assignedDate: '5/8/2022 9:45 am ET',
      clientId: '1748880',
      cardHolder: 'Yazmin Brown',
      employeeID: '54326',
      ssnLast4Digits: '3829',
      cardNumber: '9102',
      cardStatus: 'activation',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '5/9/2022',
    amount: 90000,
    rejectReason: 'Unable to Locate Account',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '1365552',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '1254441',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '7895552',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '4521363',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'ACH Deposit',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '0956445',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'Instant Fund',
  },
  {
    card: {
      assignedDate: '4/8/2022 9:45 am ET',
      clientId: '1526333',
      cardHolder: 'Kelsey Watkins',
      employeeID: '54326',
      ssnLast4Digits: '1674',
      cardNumber: '8263',
      cardStatus: 'restricted',
      cardType: 'New',
      assignedBy: 'Sync',
    },
    effectiveDate: '4/9/2022',
    amount: 50000,
    rejectReason: 'Account Restricted',
    transactionType: 'Instant Fund',
  },
]
import { useEffect, useState } from 'react';
import { Button, Flex } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { AllUsersPage1Mock, AllUsersPage2Mock } from "models/__mocks__/UserDataMock";
import { PermissionGroupsListMock1 } from 'models/__mocks__/PermissionGroupsListMock';
import UserActivityRow from 'components/dataRows/UserActivityRow';
import { ReactComponent as IconExport } from 'assets/img/icons/export.svg';


const fields = [
	{
		text: 'Date',
		keyword: 'date',
		width: '160px',
		withArrow: true,
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		width: '100px',
	},
	{
		text: 'User Name',
		width: '160px',
		keyword: 'user-name',
	},
	{
		text: 'Email',
		keyword: 'email',
		flex: 1,
	},
	{
		text: 'Activity',
		keyword: 'assigned-by',
		width: '200px',
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{key: 'created_date', name:'Date Created'}, {key: 'option2', name:'Option 2'}],
		default: 'created_date'
	},
	{
		key: 'last_updated_date',
		name: 'Last Updated Date',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'client-id',
		name: 'Client ID',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'activity',
		name: 'Activity',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
]

export default function UserActivities() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [currentPageData, setCurrentPageData] = useState<any[]>(PermissionGroupsListMock1)

	useEffect(() => {
		setCurrentPageData(currentPage % 2 === 0 ? AllUsersPage1Mock : AllUsersPage2Mock)
	}, [currentPage])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by user names' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconExport />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} ml={'16px'} colorScheme='blue'>
					Export
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader fields={fields} />
			{currentPageData.map((user, index) => (
				<UserActivityRow user={user} isLastCell={index === currentPageData.length - 1} key={index} />
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart}
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
		</Flex>
	)

}
import { Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import iPayLogo from 'assets/img/layout/iPayLogo.png';
import {
	FUSION_AUTH_API_URL_DEV,
	FUSION_AUTH_API_URL_STAGE,
	FUSION_AUTH_CLIENT_ID_DEV,
	FUSION_AUTH_CLIENT_ID_STAGE,
	FUSION_AUTH_TENANT_ID_DEV,
	FUSION_AUTH_TENANT_ID_STAGE,
	NODE_ENV_DEV
} from '../../constants/global';

export default function Auth() {
	let authUrl: string;

	if (process.env.NODE_ENV === NODE_ENV_DEV) {
		authUrl = `${FUSION_AUTH_API_URL_DEV}/api/login?clientId=${FUSION_AUTH_CLIENT_ID_DEV}&tenantId=${FUSION_AUTH_TENANT_ID_DEV}`
	} else {
		authUrl = `${FUSION_AUTH_API_URL_STAGE}/api/login?clientId=${FUSION_AUTH_CLIENT_ID_STAGE}&tenantId=${FUSION_AUTH_TENANT_ID_STAGE}`
	}

	const handleLogin = () => { window.location.href = authUrl; };

	return (
		<Flex width={'100%'} height={'100vh'} alignItems='center' justifyContent={'center'}>
			<Image src={require('assets/img/bg_login.jpg')} objectFit='cover' width={'100%'} height={'100%'} style={{ position: 'absolute' }} />
			<Flex bgColor="white" width={'480px'} height='548px' zIndex={2} borderRadius='5px' flexDirection='column' alignItems={'center'} padding='30px 20px'>
				<img src={iPayLogo} style={{ width: '104px', height: '54px' }} />
				<Divider margin={'20px 0'}/>
				<Flex flex={1} alignItems='center' justifyContent={'center'}>
					<Text ms='0px' color='#6D6E70' fontWeight='bold' fontSize='36px'>
						Welcome
					</Text>
				</Flex>
				<Button onClick={handleLogin} fontSize={'14px'} fontWeight='bold' variant="solid" borderRadius={'10px'} colorScheme='brand' width={'240px'} alignSelf='center'>
					Enter Client Portal
				</Button>
				<Button fontSize={'14px'} variant="outline" borderRadius={'10px'} borderWidth='0px' width={'240px'} alignSelf='center' colorScheme={'blue'} margin={'10px 0 0'}>
					Need Help?
				</Button>
			</Flex>
		</Flex>
	);
}

import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import TableFixedHeader from 'components/table/TableFixedHeader';
import ACHRejectsRow from 'components/dataRows/ACHRejectsRow';
import { ACHRejectsMock } from 'models/__mocks__/Card';

const fields = [
	{
		text: 'Effective Date',
		keyword: 'effective-date',
		flex: 1,
		withArrow: true,
	},
	{
		text: 'Client ID',
		flex: 1,
		keyword: 'client-id',
		display: { base: 'none', '2xl': 'flex' }
	},
	{
		text: 'Cardholder',
		flex: 1,
		keyword: 'cardholder',
	},
	{
		text: 'Employee ID',
		flex: 1,
		keyword: 'employee-id',
	},
	{
		text: 'Card Number',
		keyword: 'card-number',
		flex: 1,
	},
	{
		text: 'Card Status',
		keyword: 'card-status',
		flex: 1,
	},
	{
		text: 'Amount',
		keyword: 'amount',
		flex: 1,
	},
	{
		text: 'Reject Reason',
		keyword: 'assigned-by',
		width: '200px',
	},
	{
		text: 'Action',
		keyword: 'action',
		flex: 1,
	},
]

export default function ACHRejects() {

	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');
	
	return (
		<Card flexDirection='column' w='100%' px='0px' mb={'20px'} overflowX={{ sm: 'scroll', lg: 'hidden' }} borderWidth={'1px'} borderColor={borderColor}>
			<Flex px='25px' justify='space-between' align='center' mb={'20px'}>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					ACH Rejects
				</Text>
			</Flex>

			<TableFixedHeader fields={fields} />
			{ACHRejectsMock.slice(0, 5).map((item, index) => (
				<ACHRejectsRow data={item} isLastCell={index === ACHRejectsMock.length - 1} key={index} />
			))}
		</Card>
	);
}

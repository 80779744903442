import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';

export enum CardStatus {
	active = "active",
	restricted = "restricted",
	frozen = "frozen",
	activation = "activation",
}

export default function CardStatusView(props: { status: CardStatus }) {

	const activationBgColor = useColorModeValue('gray.900', 'darkGray.900');
	const activationTextColor = useColorModeValue('gray.800', 'darkGray.800');

	const bgColor = props.status === CardStatus.active ? globalStyles.colors.green[300] :
		props.status === CardStatus.activation ? activationBgColor:
			props.status === CardStatus.restricted ? globalStyles.colors.red[200] :
				props.status === CardStatus.frozen ? globalStyles.colors.blue[300] : 'white';

	const textColor = props.status === CardStatus.active ? globalStyles.colors.green[100] :
		props.status === CardStatus.activation ? activationTextColor:
			props.status === CardStatus.restricted ? globalStyles.colors.red[100] :
				props.status === CardStatus.frozen ? globalStyles.colors.blue[100] : 'black';
	
	const statusText = props.status === CardStatus.active ? 'Active' :
				props.status === CardStatus.activation ? 'Activation' :
					props.status === CardStatus.restricted ? 'Restricted' :
						props.status === CardStatus.frozen ? 'Frozen' : 'black';

	return (
		<Flex bgColor={bgColor} h={'18px'} borderRadius={'9px'} justifyContent='center' alignItems={'center'} width={'64px'}>
			<Text color={textColor} fontSize={'10px'} fontWeight='medium'>
				{statusText}
			</Text>
		</Flex>
	);
}

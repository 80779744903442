// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { Redirect, Route, Switch } from 'react-router-dom';
import SectionBar from 'components/sectionbar/SectionBar';
import AllUsers from './components/AllCardholders';
import DepositHistory from './components/DepositHistory';
import RecordHistory from './components/RecordHistory';

export default function CardholderManagement() {

	const borderColor = useColorModeValue('gray.400', 'darkGray.400')

	const routes = [
		{
			name: 'All cardholders',
			layout: '/admin/cardholders',
			path: '/all',
			component: AllUsers
		},
		{
			name: 'Deposit History',
			layout: '/admin/cardholders',
			path: '/deposit-history',
			component: DepositHistory,
		},
		{
			name: 'Record History',
			layout: '/admin/cardholders',
			path: '/record-history',
			component: RecordHistory,
		},
	]

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Card flexDirection='column' w='100%' mb='0px' minH='calc(100vh - 160px)' padding='24px 24px' borderWidth={'1px'} borderColor={borderColor} borderRadius='6px'>
				<SectionBar routes={routes}/>
				<Box>
					<Switch>
						{routes.map(route=>(
							<Route path={route.layout + route.path} component={route.component} key={route.path} />	
						))}
						<Redirect from='/' to='/admin/cardholders/all' />
					</Switch>
				</Box>
			</Card>
		</Box>
	);
}

// Chakra imports
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { Redirect, Route, Switch } from 'react-router-dom';
import SectionBar from 'components/sectionbar/SectionBar';
import AllUsers from './components/SyncHistory';
import SFTPInformation from './components/SFTPInformation';
import DataUpload from './components/DataUpload';
import Settings from './components/Settings';

export default function CardInventory() {

	const borderColor = useColorModeValue('gray.400', 'darkGray.400')

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Card flexDirection='column' w='100%' mb='0px' minH='calc(100vh - 160px)' padding='24px 24px' borderWidth={'1px'} borderColor={borderColor} borderRadius='6px'>
				<Text>Card Inventory</Text>
			</Card>
		</Box>
	);
}

import { Box, Flex } from '@chakra-ui/react';
import ACHRejects from './components/ACHRejects';
import CardOverview from './components/CardOverview';
import DepositSummary from './components/DepositSummary';
import InstantFundAccounts from './components/InstantFundAccounts';
import NewlyAssignedCards from './components/NewlyAssignedCards';
import SyncErrors from './components/SyncErrors';

export default function Dashboard() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Flex mb='20px'>
				<Flex flex={5} mr={'20px'}>
					<CardOverview />
				</Flex>
				<Flex flex={8}>
					<DepositSummary />
				</Flex>
			</Flex>
			<NewlyAssignedCards />
			<ACHRejects />
			<SyncErrors />
			<InstantFundAccounts />
		</Box>
	);
}

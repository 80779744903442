import { useEffect, useState } from 'react';
import { Button, Flex, Text, useFocusEffect } from "@chakra-ui/react";
import AllUsersRow from "components/dataRows/AllUsersRow";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { AllUsersPage1Mock, AllUsersPage2Mock } from "models/__mocks__/UserDataMock";
import { ReactComponent as IconExport } from 'assets/img/icons/export.svg';
import { ReactComponent as IconAdd } from 'assets/img/icons/add.svg';

const fields = [
	{
		checking: true,
		keyword: 'checking',
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		width: '100px',
		display: { base: 'none', '2xl': 'flex' }
	},
	{
		text: 'User Name(50)',
		flex: 1,
		keyword: 'user-name',
	},
	{
		text: 'Role',
		keyword: 'role',
		width: '100px',
	},
	{
		text: 'Email',
		flex: 1,
		keyword: 'employee-id',
	},
	{
		text: 'Permission Group',
		keyword: 'card-number',
		width: '200px',
	},
	{
		text: 'Last Updated',
		keyword: 'card-status',
		flex: 1.5,
		withArrow: true,
	},
	{
		text: 'Enable',
		keyword: 'amount',
		width: '80px',
	},
	{
		text: 'Action',
		keyword: 'assigned-by',
		width: '80px',
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{key: 'updated_date', name:'Updated Date'}, {key: 'option2', name:'Option 2'}],
		default: 'updated_date'
	},
	{
		key: 'client_id',
		name: 'Client ID',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'role',
		name: 'Role',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'updated_date',
		name: 'Updated Date',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'status',
		name: 'Status',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	}
]

export default function AllUsers() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [checkedUsers, setCheckedUsers] = useState<any[]>([])
	const [currentPageUsers, setCurrentPageUsers] = useState<any[]>(AllUsersPage1Mock)

	const handleSelectUser = (user: any, selected: boolean) => {
		if (selected) {
			setCheckedUsers([...checkedUsers, user])
		} else {
			setCheckedUsers([...checkedUsers.filter(item => user.clientID !== item.clientID)])
		}
	}

	const handleSelectAll = (selectAll: boolean) => {
		if (selectAll) {
			setCheckedUsers([...currentPageUsers])
		} else {
			setCheckedUsers([])
		}
	}

	useEffect(() => {
		setCurrentPageUsers(currentPage % 2 === 0 ? AllUsersPage1Mock : AllUsersPage2Mock)
		setCheckedUsers([])
	}, [currentPage])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by user names or email' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconExport />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} colorScheme='blue'>
					Export
				</Button>
				<Button variant="outline" leftIcon={<IconAdd />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} ml={'16px'} colorScheme='blue'>
					Create User
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader
				fields={fields}
				isChecked={checkedUsers.length === pageBreaking}
				isIndeterminate={checkedUsers.length > 0 && checkedUsers.length < pageBreaking}
				onCheck={handleSelectAll}
			/>
			{currentPageUsers.map((user, index) => (
				<AllUsersRow isSelected={checkedUsers.some(item => item.clientID === user.clientID)} user={user} isLastCell={index === AllUsersPage1Mock.length - 1} key={index} onSelect={handleSelectUser} />
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart}
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
		</Flex>
	)

}
import { useEffect, useState } from 'react';
import { Button, Flex, Text, useFocusEffect } from "@chakra-ui/react";
import AllUsersRow from "components/dataRows/AllUsersRow";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { AllUsersPage1Mock, AllUsersPage2Mock } from "models/__mocks__/UserDataMock";
import { PermissionGroupsListMock1, PermissionGroupsListMock2 } from 'models/__mocks__/PermissionGroupsListMock';
import PermissionGroupsRow from 'components/dataRows/PermissionGroupsRow';
import { ReactComponent as IconAdd } from 'assets/img/icons/add.svg';

const fields = [
	{
		checking: true,
		keyword: 'checking',
	},
	{
		text: 'Group Name',
		keyword: 'group-name',
		flex: 0.7,
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		width: '100px',
	},
	{
		text: 'Assigned User',
		flex: 1,
		keyword: 'user-name',
	},
	{
		text: 'Permission Count',
		keyword: 'role',
		flex: 1,
	},
	{
		text: 'Permissions',
		flex: 2,
		keyword: 'employee-id',
	},
	{
		text: 'Action',
		keyword: 'assigned-by',
		width: '120px',
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{key: 'created_date', name:'Date Created'}, {key: 'option2', name:'Option 2'}],
		default: 'created_date'
	},
	{
		key: 'client_id',
		name: 'Client ID',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
	{
		key: 'permission_ty[e',
		name: 'Permission Type',
		options: [{key: 'all', name:'All'}],
		default: 'all'
	},
]

export default function PermissionGroupList() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [checkedData, setCheckedData] = useState<any[]>([])
	const [currentPageData, setCurrentPageData] = useState<any[]>(PermissionGroupsListMock1)

	const handleSelect = (user: any, selected: boolean) => {
		if (selected) {
			setCheckedData([...checkedData, user])
		} else {
			setCheckedData([...checkedData.filter(item => user.clientID !== item.clientID)])
		}
	}

	const handleSelectAll = (selectAll: boolean) => {
		if (selectAll) {
			setCheckedData([...currentPageData])
		} else {
			setCheckedData([])
		}
	}

	useEffect(() => {
		setCurrentPageData(currentPage % 2 === 0 ? PermissionGroupsListMock1 : PermissionGroupsListMock2)
		setCheckedData([])
	}, [currentPage])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by keywords' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconAdd />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} ml={'16px'} colorScheme='blue'>
					Create Group
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader
				fields={fields}
				isChecked={checkedData.length === pageBreaking}
				isIndeterminate={checkedData.length > 0 && checkedData.length < pageBreaking}
				onCheck={handleSelectAll}
			/>
			{currentPageData.map((permission, index) => (
				<PermissionGroupsRow isSelected={checkedData.some(item => item.clientID === permission.clientID)} permission={permission} isLastCell={index === currentPageData.length - 1} key={index} onSelect={handleSelect} />
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart}
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
		</Flex>
	)

}
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import TableFixedHeader from 'components/table/TableFixedHeader';
import { SyncErrorsMock } from 'models/__mocks__/SyncErrorsMock';
import SyncErrorRow from 'components/dataRows/SyncErrorRow';
import { globalStyles } from 'theme/styles';

const fields = [
	{
		text: 'Job ID',
		keyword: 'effective-date',
		flex: 1,
	},
	{
		text: 'Client ID',
		flex: 1,
		keyword: 'client-id',
	},
	{
		text: 'Cardholder',
		flex: 1,
		keyword: 'cardholder',
	},
	{
		text: 'Employee ID',
		flex: 1,
		keyword: 'employee-id',
	},
	{
		text: 'Account',
		keyword: 'card-number',
		flex: 1,
	},
	{
		text: 'SSN',
		keyword: 'amount',
		flex: 1,
		display: { base: 'none', '2xl': 'flex' }
	},
	{
		text: 'Error Reason',
		keyword: 'assigned-by',
		width: '400px',
	},
]

export default function SyncErrors() {

	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');
	
	return (
		<Card flexDirection='column' w='100%' px='0px' mb={'20px'} overflowX={{ sm: 'scroll', lg: 'hidden' }} borderWidth={'1px'} borderColor={borderColor}>
			<Flex px='25px' justify='space-between' align='center' mb={'20px'}>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Sync Errors
				</Text>
				<Flex direction='row' alignItems={'center'}>
					<Text color={textColor} fontSize='16px' fontWeight='bold' lineHeight={'16px'}>
						Last Syncs: June 9, 2022
					</Text>
					<Flex w={'127px'} h={'45px'} alignItems='center' justifyContent={'center'} bgColor={globalStyles.colors.primary[100]} borderRadius='25px' ml={'20px'}>
						<Text color={'white'} fontSize='16px' fontWeight='medium' lineHeight={'19px'}>
							Run Sync
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<TableFixedHeader fields={fields} />
			{SyncErrorsMock.map((item, index) => (
				<SyncErrorRow data={item} isLastCell={index === SyncErrorsMock.length - 1} key={index} />
			))}
		</Card>
	);
}

import { Box, Divider, Flex, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { globalStyles } from "theme/styles";

export default function SectionBar(props: { routes: RoutesType[] }) {

  const location = useLocation()
  const deselectedTextColor = useColorModeValue('gray.200', 'darkGray.200')

  const activeRoute = (routeName: string) => {
		return location.pathname.includes(routeName);
	};

  return (
    <Flex direction='column'>
      <Flex direction='row' pl={'16px'}>
        {props.routes.map(route => (
          <Flex h='48px' padding={'0 20px'} justifyContent='center' alignItems={'center'} position='relative' key={route.path}>
            <NavLink to={route.layout + route.path}>
              <Text color={activeRoute(route.layout + route.path) ? globalStyles.colors.primary[100] : deselectedTextColor} fontWeight='bold'>
                {route.name}
              </Text>
            </NavLink>
            {activeRoute(route.layout + route.path) && <Box bgColor={globalStyles.colors.primary[100]} position='absolute' bottom={0} left={0} right={0} h={'6px'} />}
          </Flex>
        ))}
      </Flex>
      <Divider />
    </Flex>
  )
}
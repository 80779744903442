import React, { useState} from 'react';
import { Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import TableFixedHeader from 'components/table/TableFixedHeader';
import { SyncErrorsMock } from 'models/__mocks__/SyncErrorsMock';
import InstantFundAccountRow from 'components/dataRows/InstantFundAccountRow';
import { InstantFundsMocks } from 'models/__mocks__/InstantFundsMocks';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconCurrentBalance } from 'assets/img/icons/current-balance.svg';
import ModalReturnFund from '../../../../components/modals/ModalReturnFund';

const fields = [
	{
		text: 'Date',
		keyword: 'effective-date',
		withArrow: 'true',
		flex: 1,
	},
	{
		text: 'Client ID',
		flex: 1,
		keyword: 'client-id',
	},
	{
		text: 'Cardholder',
		flex: 1,
		keyword: 'cardholder',
	},
	{
		text: 'Employee ID',
		flex: 1,
		keyword: 'employee-id',
	},
	{
		text: 'Card Status',
		keyword: 'card-number',
		flex: 1,
	},
	{
		text: 'Amount',
		keyword: 'amount',
		flex: 1,
	},
	{
		text: 'Action',
		keyword: 'assigned-by',
		flex: 1,
	},
]

export default function InstantFundAccounts() {

	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [returnFundData, setReturnFundData] = useState<any>();

	const handleReturnFund = (data: any) => {
		setReturnFundData(data)
		onOpen()
	}
	
	return (
		<Card flexDirection='column' w='100%' px='0px' mb={'0px'} overflowX={{ sm: 'scroll', lg: 'hidden' }} borderWidth={'1px'} borderColor={borderColor}>
			<Flex px='25px' justify='space-between' align='center' mb={'20px'}>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Instant Fund Accounts
				</Text>
				<Flex direction='column' alignItems={'center'}>
					<Text color={globalStyles.colors.primary[100]} fontSize='40px' fontWeight='bold'>
						$1,716.78
					</Text>
					<Flex direction='row' alignItems='center'>
						<Text color={textColor} fontSize='16px' fontWeight='bold'>
							Current Balance
						</Text>
						<IconCurrentBalance />
					</Flex>
				</Flex>
			</Flex>

			<TableFixedHeader fields={fields} />
			{InstantFundsMocks.map((item, index) => (
				<InstantFundAccountRow data={item} isLastCell={index === SyncErrorsMock.length - 1} key={index} onReturnFund={handleReturnFund} />
			))}
			{returnFundData && <ModalReturnFund isOpen={isOpen} onClose={onClose} fundData={returnFundData} amount={returnFundData.amount}/>}
		</Card>
	);
}

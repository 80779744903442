import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
		primary: {
			100: '#0072BC',
			200: '#EBF2F9',
			300: '#A6C0D9',
			400: '#EBF2F9',
			500: '#FFFFFF',
		},
		gray: {
			100: '#707070',
			200: '#979899',
			300: '#AFAFAF',
			400: '#C8C8C9',
			500: '#F5F5F5',
			600: '#FAFAFA',
			700: '#FFFFFF',
			800: '#909090',
			900: '#E2E2E2',
		},
		darkGray: {
			100: '#909090',
			200: '#777879',
			300: '#4F4F4F',
			400: '#383839',
			500: '#141414',
			600: '#0D0D0D',
			700: '#000000',
			800: '#707070',
			900: '#2E2E2E',
		},
		brand: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#7551FF',
			450: '#2C79C9',
			500: '#0072BC',
			600: '#73A9FF',
			700: '#02044A',
			800: '#190793',
			900: '#11047A'
		},
		brandScheme: {
			100: '#E9E3FF',
			200: '#7551FF',
			300: '#7551FF',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		brandTabs: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#422AFB',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		secondaryGray: {
			100: '#E0E5F2',
			200: '#E1E9F8',
			300: '#F5F5F5',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559'
		},
		red: {
			100: '#F75D5D',
			200: '#FFC6C6',
			300: '#FDE1E1',
			400: '#FCEBEB',
			500: '#EE5D50',
			600: '#E31A1A'
		},
		blue: {
			50: '#EFF4FB',
			100: '#0072BC',
			200: '#C0DCEF',
			300: '#EBF2F9',
			500: '#3965FF'
		},
		orange: {
			100: '#F4CA3E',
			200: '#F9DF9E',
			300: '#FCEBB4',
			400: '#FFF7E3',
			500: '#FFB547'
		},
		green: {
			100: '#A5BE00',
			200: '#D5E663',
			300: '#E4EF99',
			400: '#EDF2CB',
			500: '#01B574'
		},
		navy: {
			50: '#d0dcfb',
			100: '#aac0fe',
			200: '#a3b9f8',
			300: '#728fea',
			400: '#3652ba',
			500: '#1b3bbb',
			600: '#24388a',
			700: '#1B254B',
			800: '#111c44',
			900: '#0b1437'
		},
		// gray: {
		// 	100: '#FAFCFE',
		// 	200: '#979899',
		// 	800: '#5f5f5f',
		// }
	},
	styles: {
		global: (props: any) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('secondaryGray.300', 'navy.900')(props),
				fontFamily: "'Lato', sans-serit",
				letterSpacing: '-0.5px'
			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: 'DM Sans'
			}
		})
	}
};

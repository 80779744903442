import { Button, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import CardStatusView from 'components/dataDisplay/CardStatusView';
import { ReactComponent as IconReturnFund } from 'assets/img/icons/return-fund.svg';

export default function InstantFundAccountRow(props: { data: any, isLastCell?: boolean, onReturnFund?: (data: any) => void }) {

	const { data, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.date}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.clientID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={'16px'} textDecoration='underline'>
						{data.cardHolder}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.employeeID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<CardStatusView status={data.cardStatus} />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.amount}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1} direction='row'>
					<Button leftIcon={<IconReturnFund />} variant='link' color={textColor} fontSize='14px' fontWeight={'bold'} onClick={() => props.onReturnFund(data)}>
						Return Fund
					</Button>
				</Flex>
			</Flex>
			{!props.isLastCell && <Divider variant="dashed" />}
		</>
	);
}

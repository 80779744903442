import { Box, Checkbox, Divider, Flex, IconButton, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconMoreHoriz } from 'assets/img/icons/more-horiz.svg';

export default function AllUsersRow(props: { user: any, onSelect: (user: any, selected: boolean) => void, isLastCell?: boolean, isSelected?: boolean }) {

	const { user, onSelect, isSelected, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	const textFontSize = '14px'

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'}>
					<Checkbox isChecked={isSelected} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(user, e.target.checked)}/>
					<Box w='20px' />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'100px'} display={{ base: 'none', '2xl': 'flex' }}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.clientID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={textFontSize} textDecoration='underline'>
						{user.name}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'100px'}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.role}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.email}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'200px'}>
					<Text color={textColor} fontSize={textFontSize}>
						Default Group, and 1 more
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1.5}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.lastUpdated}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='80px'>
					<Switch isChecked={user.enabled} variant='solid' />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width='80px' direction='row'>
					<IconButton aria-label='more' icon={<IconMoreHoriz />} variant='ghost' colorScheme={'blue'} w={'40px'} h={'40px'} borderRadius='20px'/>
				</Flex>
			</Flex>
			{!isLastCell && <Divider variant="dashed" />}
		</>
	);
}

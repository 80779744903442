import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import { globalStyles } from 'theme/styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fundData: any;
  amount: number;
}

const ModalReturnFund = ({
  isOpen,
  onClose,
  fundData,
  amount,
}: Props) => {

  const textColor = useColorModeValue('gray.100', 'darkGray.100')
  const panelBgColor = useColorModeValue('gray.500', 'darkGray.500')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'} fontWeight='bold' fontSize={'20px'} color={textColor}>Return Fund</ModalHeader>
        <ModalCloseButton />
        <ModalBody w={'100%'}>
          <Text color={globalStyles.colors.green[100]} fontSize={52} textAlign='center'>
            ${(amount / 100).toFixed(2)}
          </Text>
          <Flex direction='row' alignItems={'center'} justifyContent='center'>
            <Text color={textColor} fontSize={16} fontWeight='bold'>
              Available for Fund Return
            </Text>
            <InfoOutlineIcon ml={'10px'} color={globalStyles.colors.primary[100]}/>
          </Flex>
          
          <Flex w='100%' h={'100px'} bgColor={panelBgColor} borderRadius={'15px'} mt={'20px'} direction='row' justifyContent={'space-around'} alignItems='center'>
            <Flex direction='column'>
              <Text color={textColor}>
                Cardholder Name
              </Text>
              <Text fontWeight={'bold'} color={textColor}>
                {fundData.cardHolder}
              </Text>
            </Flex>
            <Flex direction='column'>
              <Text color={textColor}>
                Employee ID
              </Text>
              <Text fontWeight={'bold'} color={textColor}>
                {fundData.employeeID}
              </Text>
            </Flex>
            <Flex direction='column'>
              <Text color={textColor}>
                Card Number
              </Text>
              <Text fontWeight={'bold'} color={textColor}>
                ***{fundData.cardNumber}
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent='center'>
          <Button variant={'outline'} mr={3} onClick={onClose} borderRadius={5} fontWeight='normal'>
            Cancel
          </Button>
          <Button bgColor={globalStyles.colors.primary[100]} textColor='white' borderRadius={5} fontWeight='normal'>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalReturnFund
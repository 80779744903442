export const InstantFundsMocks = [
  {
    date: '6/9/2022',
    clientID: '4956325',
    cardHolder: 'Laurie Glover',
    employeeID: '57546',
    cardNumber: '3667',
    cardStatus: 'active',
    amount: 50000,
  },
  {
    date: '6/9/2022',
    clientID: '5432112',
    cardHolder: 'Hetty Stafford',
    employeeID: '57546',
    cardNumber: '1873',
    cardStatus: 'activation',
    amount: 28000,
  },
  {
    date: '6/8/2022',
    clientID: '5432112',
    cardHolder: 'Umer Ibarra',
    employeeID: '87140',
    cardNumber: '5452',
    cardStatus: 'frozen',
    amount: 32000,
  },
  {
    date: '6/8/2022',
    clientID: '5432112',
    cardHolder: 'Umer Ibarra',
    employeeID: '87140',
    cardNumber: '5452',
    cardStatus: 'activation',
    amount: 32000,
  },
  {
    date: '6/8/2022',
    clientID: '5432112',
    cardHolder: 'Umer Ibarra',
    employeeID: '87140',
    cardNumber: '5452',
    cardStatus: 'restricted',
    amount: 32000,
  },
  {
    date: '6/8/2022',
    clientID: '5432112',
    cardHolder: 'Umer Ibarra',
    employeeID: '87140',
    cardNumber: '5452',
    cardStatus: 'active',
    amount: 32000,
  },
]
import { Button, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconReturnFund } from 'assets/img/icons/return-fund.svg';

export default function RecordHistoryRow(props: { data: any, isLastCell?: boolean }) {

	const { data, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.effectiveDate}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.card.clientId}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={2}>
					<Text color={globalStyles.colors.primary[100]} fontSize={'16px'} textDecoration='underline'>
						{data.card.cardHolder}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						{data.card.employeeID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={'16px'}>
						•••-•••-{data.card.ssnLast4Digits}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'120px'} direction='row'>
					Created
				</Flex>
			</Flex>
			{!isLastCell && <Divider variant="dashed" />}
		</>
	);
}

import { useEffect, useState } from 'react';
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { ReactComponent as IconExport } from 'assets/img/icons/export.svg';
import { ACHRejectsMock } from 'models/__mocks__/Card';
import DepositHistoryRow from 'components/dataRows/DepositHistoryRow';
import ModalReturnFund from 'components/modals/ModalReturnFund';
import RecordHistoryRow from 'components/dataRows/RecordHistoryRow';

const fields = [
	{
		text: 'Date',
		keyword: 'date',
		withArrow: true,
		flex: 1,
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		flex: 1,
	},
	{
		text: 'Cardholder',
		flex: 2,
		keyword: 'user-name',
	},
	{
		text: 'Employee ID',
		keyword: 'employee-id',
		flex: 1,
	},
	{
		text: 'SSN',
		flex: 1,
		keyword: 'ssn',
	},
	{
		text: 'Action',
		width: '120px',
		keyword: 'action',
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{ key: 'date_created', name: 'Date Created' }, { key: 'option2', name: 'Option 2' }],
		default: 'date_created'
	},
	{
		key: 'client_id',
		name: 'Client ID',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'action',
		name: 'Action',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'date',
		name: 'Date',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
]

export default function RecordHistory() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [currentPageItems, setCurrentPageItems] = useState<any[]>(ACHRejectsMock)

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [returnFundData, setReturnFundData] = useState<any>();

	const handleReturnFund = (data: any) => {
		setReturnFundData(data)
		onOpen()
	}

	useEffect(() => {
		setCurrentPageItems(currentPage % 2 === 0 ? ACHRejectsMock : ACHRejectsMock)
	}, [currentPage])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by keywords' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconExport />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} colorScheme='blue'>
					Export
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader fields={fields} />
			{currentPageItems.map((item, index) => (
				<RecordHistoryRow
					data={item}
					isLastCell={index === currentPageItems.length - 1}
					key={index}
				/>
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart}
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
			{returnFundData && <ModalReturnFund isOpen={isOpen} onClose={onClose} fundData={returnFundData?.card} amount={returnFundData.amount} />}
		</Flex>
	)

}
// Chakra imports
import { Box, Button, Divider, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import LineChart from 'components/charts/LineChart';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdBarChart, MdOutlineCalendarToday } from 'react-icons/md';
// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { globalStyles } from 'theme/styles';
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from 'variables/charts';

export default function CardOverview(props: { [x: string]: any }) {
	const { ...rest } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');

	return (
		<Card justifyContent='center' alignItems='center' flexDirection='column' w='100%' mb='0px' padding='24px 24px' borderWidth={'1px'} borderColor={borderColor} {...rest}>
			<Flex w='100%' h='420px' direction="column">
				<Text color={textColor} fontSize='xl' fontWeight='600'>
					Card Overview
				</Text>
				<Flex
					flex={1}
					direction='column'
					justifyContent='center'
					alignItems={'center'}
				>
					<Text color={globalStyles.colors.brand[500]} fontSize='54px' fontWeight='700'>
						2,475
					</Text>
					<Text color={textColor} fontSize='16px' fontWeight='500'>
						Total Payroll Cards
					</Text>
				</Flex>
				<Divider />
				<Flex
					direction='row'
					mt={30}
					alignItems='center'
				>
					<Box w='16px' h='16px' borderRadius='8px' bgColor={globalStyles.colors.green[100]} />
					<Text color={textColor} fontSize='14px' fontWeight='medium' ml={'8px'}>
						Active (54.46%)
					</Text>
					<Box w='16px' h='16px' borderRadius='8px' bgColor={globalStyles.colors.brand[500]} ml={'20px'} />
					<Text color={textColor} fontSize='14px' fontWeight='medium' ml={'8px'}>
						Activation (28.20%)
					</Text>
					<Box w='16px' h='16px' borderRadius='8px' bgColor={globalStyles.colors.red[100]} ml={'20px'} />
					<Text color={textColor} fontSize='14px' fontWeight='medium' ml={'8px'}>
						Restricted(17.33%)
					</Text>
				</Flex>
				<Flex
					direction='row'
					mt={'20px'}
					mb={'20px'}
					h={'30px'}
				>
					<Flex flex={1348} bgColor={globalStyles.colors.green[100]} borderTopLeftRadius={'15px'} borderBottomLeftRadius={'15px'} h='100%' alignItems={'center'} justifyContent='center'>
						<Text color='white' fontSize='16px' fontWeight={'bold'}>
							1,348
						</Text>
					</Flex>
					<Flex flex={698} bgColor={globalStyles.colors.brand[500]} h='100%' alignItems={'center'} justifyContent='center'>
						<Text color='white' fontSize='16px' fontWeight={'bold'}>
							698
						</Text>
					</Flex>
					<Flex flex={429} bgColor={globalStyles.colors.red[100]} h='100%' alignItems={'center'} justifyContent='center' borderTopRightRadius={'15px'} borderBottomRightRadius={'15px'}>
						<Text color='white' fontSize='16px' fontWeight={'bold'}>
							429
						</Text>
					</Flex>
				</Flex>

				{/* <Button bg={boxBg} fontSize='sm' fontWeight='500' color={textColorSecondary} borderRadius='7px'>
					<Icon as={MdOutlineCalendarToday} color={textColorSecondary} me='4px' />
					This month
				</Button>
				<Button
					ms='auto'
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}>
					<Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
				</Button> */}
			</Flex>
			{/* <Flex w='100%' flexDirection={{ base: 'column', lg: 'row' }}>
				<Flex flexDirection='column' me='20px' mt='28px'>
					<Text color={textColor} fontSize='34px' textAlign='start' fontWeight='700' lineHeight='100%'>
						$37.5K
					</Text>
					<Flex align='center' mb='20px'>
						<Text color='secondaryGray.600' fontSize='sm' fontWeight='500' mt='4px' me='12px'>
							Total Spent
						</Text>
						<Flex align='center'>
							<Icon as={RiArrowUpSFill} color='green.500' me='2px' mt='2px' />
							<Text color='green.500' fontSize='sm' fontWeight='700'>
								+2.45%
							</Text>
						</Flex>
					</Flex>

					<Flex align='center'>
						<Icon as={IoCheckmarkCircle} color='green.500' me='4px' />
						<Text color='green.500' fontSize='md' fontWeight='700'>
							On track
						</Text>
					</Flex>
				</Flex>
				<Box minH='260px' minW='75%' mt='auto'>
					<LineChart chartData={lineChartDataTotalSpent} chartOptions={lineChartOptionsTotalSpent} />
				</Box>
			</Flex> */}
		</Card>
	);
}

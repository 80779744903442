import { UserRole } from "models/User";

export const AllUsersPage1Mock = [
  {
    clientID: '0942653',
    name: 'Siobhan Preece',
    role: UserRole.admin,
    email: 'example@mail.com',
    permissions: ['default', 'aa', 'b'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '1235648',
    name: 'Chad Edwards',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: false,
  },
  {
    clientID: '6489213',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '3125463',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '1956874',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '1546999',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '4625123',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '8525636',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  // {
  //   clientID: '7985465',
  //   name: 'Alysia Wells',
  //   role: UserRole.user,
  //   email: 'example@mail.com',
  //   permissions: ['default', 'a'],
  //   lastUpdated: '6/9/2022 11:15 am ET',
  //   enabled: true,
  // },
  // {
  //   clientID: '6589000',
  //   name: 'Alysia Wells',
  //   role: UserRole.user,
  //   email: 'example@mail.com',
  //   permissions: ['default', 'a'],
  //   lastUpdated: '6/9/2022 11:15 am ET',
  //   enabled: false,
  // },
]

export const AllUsersPage2Mock = [
  {
    clientID: '2132515',
    name: 'Hanan Wilkins',
    role: UserRole.admin,
    email: 'example@mail.com',
    permissions: ['default', 'aa', 'b'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '6396545',
    name: 'Kaison Kaur',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: false,
  },
  {
    clientID: '1285496',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '3065444',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '1000985',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '9784563',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '1115222',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '6665332',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '6489213',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: true,
  },
  {
    clientID: '00032578',
    name: 'Alysia Wells',
    role: UserRole.user,
    email: 'example@mail.com',
    permissions: ['default', 'a'],
    lastUpdated: '6/9/2022 11:15 am ET',
    enabled: false,
  },
]
// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import BarChart from 'components/charts/BarChart';
import React from 'react';
import { barChartDataConsumption, barChartOptionsConsumption } from 'variables/charts';
import { MdBarChart } from 'react-icons/md';
import { globalStyles } from 'theme/styles';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';

export default function DepositSummary(props: { [x: string]: any }) {
	const { ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400')
	
	return (
		<Card alignItems='center' flexDirection='column' w='100%' borderWidth={'1px'} borderColor={borderColor} {...rest}>
			<Flex align='center' w='100%' px='15px' py='10px'>
				<Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
					Deposit Summary
				</Text>
				<CalendarIcon />
				<Text color={textColor} fontSize='14px' fontWeight='bold' ml={'8px'}>
					Past 10d
				</Text>
			</Flex>

			<Flex flex={1} alignItems='center' justifyContent='center' direction='row'>
				<Box w='28px' h='16px' borderRadius='8px' bgColor={globalStyles.colors.brand[600]} />
				<Text color={textColor} fontSize='14px' fontWeight='medium' ml={'8px'}>
					Instant Fund
				</Text>
				<Box w='28px' h='16px' borderRadius='8px' bgColor={globalStyles.colors.green[100]} ml={'20px'} />
				<Text color={textColor} fontSize='14px' fontWeight='medium' ml={'8px'}>
					ACH Deposit
				</Text>
			</Flex>

			<Box h='250px' w='100%'>
				<BarChart chartData={barChartDataConsumption} chartOptions={barChartOptionsConsumption} />
			</Box>
		</Card>
	);
}

export const DataSyncMocks = [
  {
    jobID: '35425',
    clientID: '0942653',
    method: 'File Upload(Portal)',
    created: '6/9/2022 12:30pm ET',
    completed: '',
    jobStatus: 'Processing',
  },
  {
    jobID: '76545',
    clientID: '4956325',
    method: 'Integration (Name)',
    created: '6/9/2022 9:45am ET',
    completed: '6/9/2022 3:30pm ET',
    jobStatus: 'Success',
  },
  {
    jobID: '87915',
    clientID: '0945213',
    method: 'File Upload(SFTP)',
    created: '6/9/2022 9:45am ET',
    completed: '',
    jobStatus: 'Fail',
  },
  {
    jobID: '00098',
    clientID: '3845620',
    method: 'File Upload(Portal)',
    created: '6/9/2022 10:45am ET',
    completed: '',
    jobStatus: 'Fail',
  },
  {
    jobID: '10995',
    clientID: '054126',
    method: 'File Upload(Portal)',
    created: '6/10/2022 10:45am ET',
    completed: '',
    jobStatus: 'Progressing',
  },
  {
    jobID: '58110',
    clientID: '1532109',
    method: 'Integration (Name)',
    created: '6/11/2022 10:45am ET',
    completed: '6/15/2022 10:45am ET',
    jobStatus: 'Success',
  },
  {
    jobID: '01149',
    clientID: '0085313',
    method: 'Integration (Name)',
    created: '6/11/2022 10:45am ET',
    completed: '6/15/2022 10:45am ET',
    jobStatus: 'Success',
  },
  {
    jobID: '00536',
    clientID: '0013978',
    method: 'File Upload (Portal)',
    created: '6/11/2022 10:45am ET',
    completed: '6/15/2022 10:45am ET',
    jobStatus: 'Success',
  },
]
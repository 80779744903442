import { useEffect, useState } from 'react';
import { Button, Flex } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import Paging from "components/table/Paging";
import TableFixedHeader from "components/table/TableFixedHeader";
import TableSortOptionsBar from "components/table/TableSortOptionsBar";
import { ReactComponent as IconExport } from 'assets/img/icons/export.svg';
import { ReactComponent as IconAdd } from 'assets/img/icons/add.svg';
import CardholderRow from 'components/dataRows/CardholderRow';
import { CardholdersMock1 } from 'models/__mocks__/CardholdersMocks';

const fields = [
	{
		checking: true,
		keyword: 'checking',
	},
	{
		text: 'Client ID',
		keyword: 'client-id',
		width: '80px',
	},
	{
		text: 'Cardholder(624)',
		width: '120px',
		keyword: 'user-name',
	},
	{
		text: 'Employee ID',
		keyword: 'employee-id',
		display: { base: 'none', '2xl': 'flex' },
		width: '100px',
	},
	{
		text: 'SSN',
		width: '110px',
		keyword: 'ssn',
		display: { base: 'none', '2xl': 'flex' }
	},
	{
		text: 'Card Number',
		keyword: 'card-number',
		width: '100px',
	},
	{
		text: 'Card Status',
		keyword: 'card-status',
		width: '80px',
	},
	{
		text: 'Assignment Date',
		keyword: 'assignment-date',
		width: '160px',
		align: 'center',
		withArrow: true,
	},
	{
		text: 'Action',
		keyword: 'action',
		align: 'center',
		flex: 1,
	},
]

const sortFilters = [
	{
		key: 'sort_by',
		name: 'Sort By',
		options: [{ key: 'assignment_date', name: 'Assignment Date' }, { key: 'option2', name: 'Option 2' }],
		default: 'assignment_date'
	},
	{
		key: 'client_id',
		name: 'Client ID',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'card_status',
		name: 'Card Status',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
	{
		key: 'assignment_date',
		name: 'Assignment Date',
		options: [{ key: 'all', name: 'All' }],
		default: 'all'
	},
]

export default function AllCardholders() {

	const pageBreaking = 10

	const [currentPage, setCurrentPage] = useState(0)
	const [currentPagingStart, setCurrentPagingStart] = useState(0)
	const [checkedItems, setCheckedItems] = useState<any[]>([])
	const [currentPageItems, setCurrentPageItems] = useState<any[]>(CardholdersMock1)

	const handleSelectItem = (item: any, selected: boolean) => {
		if (selected) {
			setCheckedItems([...checkedItems, item])
		} else {
			setCheckedItems([...checkedItems.filter(checkedItem => item.clientId !== checkedItem.clientId)])
		}
	}

	const handleSelectAll = (selectAll: boolean) => {
		if (selectAll) {
			setCheckedItems([...currentPageItems])
		} else {
			setCheckedItems([])
		}
	}

	useEffect(() => {
		setCurrentPageItems(currentPage % 2 === 0 ? CardholdersMock1 : CardholdersMock1)
		setCheckedItems([])
	}, [currentPage])

	return (
		<Flex direction='column' pt={'20px'}>
			<Flex direction='row' alignItems={'center'}>
				<SearchBar placeholder='Search by keywords' />
				<Flex flex={1} />
				<Button variant="outline" leftIcon={<IconExport />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} colorScheme='blue'>
					Export
				</Button>
				<Button variant="outline" leftIcon={<IconAdd />} borderRadius={'20px'} fontWeight='normal' fontSize={'16px'} ml={'16px'} colorScheme='blue'>
					Assign New Card
				</Button>
			</Flex>
			<TableSortOptionsBar fields={sortFilters} />
			<TableFixedHeader
				fields={fields}
				isChecked={checkedItems.length === pageBreaking}
				isIndeterminate={checkedItems.length > 0 && checkedItems.length < pageBreaking}
				onCheck={handleSelectAll}
			/>
			{currentPageItems.map((item, index) => (
				<CardholderRow
					isSelected={checkedItems.some(checkedItem => item.clientId === checkedItem.clientId)}
					card={item}
					isLastCell={index === currentPageItems.length - 1}
					key={index}
					onSelect={handleSelectItem}
				/>
			))}
			<Paging
				total={pageBreaking}
				current={currentPage}
				pageStart={currentPagingStart}
				onPrevious={() => {
					if (currentPagingStart > 0) {
						setCurrentPagingStart(currentPagingStart - pageBreaking)
						setCurrentPage(currentPagingStart - 1)
					}
				}}
				onNext={() => {
					setCurrentPagingStart(currentPagingStart + pageBreaking)
					setCurrentPage(currentPagingStart + pageBreaking)
				}}
				onPageChanged={(page: number) => {
					setCurrentPage(page)
				}}
			/>
		</Flex>
	)

}
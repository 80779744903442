import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import TableFixedHeader from 'components/table/TableFixedHeader';
import CardRow from 'components/dataRows/CardRow';
import { globalStyles } from 'theme/styles';

import { ReactComponent as IconArrowToTopLeft } from 'assets/img/icons/icon-arrow-to-top-left.svg'
import { assignedCards } from 'models/__mocks__/Card';

export default function NewlyAssignedCards() {

	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const borderColor = useColorModeValue('gray.400', 'darkGray.400');
	const secondaryTextColor = useColorModeValue('gray.200', 'darkGray.200');

	const fields = [
		{
			text: 'Assignment Date',
			keyword: 'assignment-date',
			width: {base: '140px', '2xl': '200px'},
			withArrow: true,
		},
		{
			text: 'Client ID',
			width: {base: '80px', '2xl': '120px'},
			keyword: 'client-id',
		},
		{
			text: 'Cardholder',
			flex: 1,
			keyword: 'cardholder',
		},
		{
			text: 'Employee ID',
			flex: 1,
			keyword: 'employee-id',
		},
		{
			text: 'SSN',
			keyword: 'ssn',
			display: { base: 'none', '2xl': 'flex' },
			flex: 1,
		},
		{
			text: 'Card Number',
			keyword: 'card-number',
			flex: 1,
		},
		{
			text: 'Card Status',
			keyword: 'card-status',
			flex: 1,
		},
		{
			text: 'Card Type',
			keyword: 'card-type',
			flex: 1,
		},
		{
			text: 'Assigned by',
			keyword: 'assigned-by',
			flex: 1,
		},
		{
			text: 'Action',
			keyword: 'action',
			width: '140px',
		},
	]
	return (
		<Card flexDirection='column' w='100%' px='0px' mb={'20px'} overflowX={{ sm: 'scroll', lg: 'hidden' }} borderWidth={'1px'} borderColor={borderColor}>
			<Flex px='25px' justify='space-between' align='center' mb={'20px'}>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Newly Assigned Cards
				</Text>
				<Flex direction='row' alignItems={'center'}>
					<Flex direction='column' alignItems={'flex-end'}>
						<Flex direction='row' alignItems={'center'}>
							<Text color={globalStyles.colors.primary[100]} fontSize='40px' lineHeight={'50px'} mr={'16px'}>
								116
							</Text>
							<IconArrowToTopLeft />
						</Flex>
						<Text color={secondaryTextColor} fontSize='16px' fontWeight='bold' lineHeight={'16px'}>
							Assigned in Past 7 Days
						</Text>
					</Flex>
					<Flex w={'127px'} h={'45px'} alignItems='center' justifyContent={'center'} bgColor={globalStyles.colors.primary[100]} borderRadius='25px' ml={'20px'}>
						<Text color={'white'} fontSize='16px' fontWeight='medium' lineHeight={'19px'}>
							Run Sync
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<TableFixedHeader fields={fields} />
			{assignedCards.map((card, index) => (
				<CardRow key={index} card={card} isLastCell={index === assignedCards.length - 1}/>
			))}
		</Card>
	);
}

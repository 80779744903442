import { Box, Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as IconArrowDown } from 'assets/img/icons/arrow-down.svg';
import { globalStyles } from 'theme/styles';
import { ReactComponent as IconInstantFund } from 'assets/img/icons/icon-instant-fund.svg';
import CardStatusView, { CardStatus } from 'components/dataDisplay/CardStatusView';

export default function ACHRejectsRow(props: { data: any, isLastCell?: boolean }) {

	const { data, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');
	const fontSize = { base: '14px', '2xl': '16px' }

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{data.effectiveDate}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1} display={{ base: 'none', '2xl': 'flex' }}>
					<Text color={textColor} fontSize={fontSize}>
						{data.card.clientId}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={globalStyles.colors.primary[100]} fontSize={fontSize} textDecoration='underline'>
						{data.card.cardHolder}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						{data.card.employeeID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						• {data.card.cardNumber}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<CardStatusView status={data.card.cardStatus} />
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={fontSize}>
						${data.amount / 100}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'200px'}>
					<Text color={textColor} fontSize={fontSize}>
						{data.rejectReason}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} direction='row' w={'140px'}>
					<IconInstantFund />
					<Text color={textColor} fontSize={fontSize} fontWeight='bold' ml={'5px'}>
						Instant Fund
					</Text>
				</Flex>
			</Flex>
			{!props.isLastCell && <Divider variant="dashed" />}
		</>
	);
}

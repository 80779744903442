import { Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';

export default function UserActivityRow(props: { user: any, isLastCell?: boolean }) {

	const { user, isLastCell } = props;
	const textColor = useColorModeValue('gray.100', 'darkGray.100');

	const textFontSize = '14px'

	return (
		<>
			<Flex direction={'row'} alignItems='center' minH='44px' justifyContent='space-around' padding={'0 0 0 30px'}>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'160px'}>
					<Text color={textColor} fontSize={textFontSize}>
						6/9/2022 9:45 am ET
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'100px'}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.clientID}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} w={'160px'}>
					<Text color={globalStyles.colors.primary[100]} fontSize={textFontSize} textDecoration='underline'>
						{user.name}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} flex={1}>
					<Text color={textColor} fontSize={textFontSize}>
						{user.email}
					</Text>
				</Flex>
				<Flex h='100%' justifyContent={'flex-start'} alignItems={'center'} width={'200px'}>
					<Text color={textColor} fontSize={textFontSize}>
						Instant Fund,  Password Reset
					</Text>
				</Flex>
			</Flex>
			{!isLastCell && <Divider variant="dashed" />}
		</>
	);
}

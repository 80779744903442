/*eslint-disable*/

import { Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex
			zIndex='3'
			flexDirection={{
				base: 'column',
			}}
			alignItems={{
				base: 'center',
			}}
			justifyContent='space-between'
			px={{ base: '10px', md: '50px' }}
			pb='20px'
			fontSize={'14px'}
			>
			<Text
				color={textColor}
				textAlign={{
					base: 'center',
					xl: 'start'
				}}
				>
				<Link mx='3px' color={textColor} href='#' target='_blank' fontWeight='normal' rel="noopener noreferrer">
					Terms of Use
				</Link>
				<Text as='span' fontWeight='500' ms='4px'>
					|
				</Text>
				<Link mx='3px' color={textColor} href='#' target='_blank' rel='noopener' fontWeight='normal'>
					Online Privacy Policy
				</Link>
				{' '}
				&copy; {new Date().getFullYear()}
				{' '}Kurensē. All Rights Reserved.
			</Text>
		</Flex>
	);
}
